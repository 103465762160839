import {
  ExportListType,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  DocumentExport,
  VoiceTradeData,
  CashTransaction,
  BankAccountData,
  VoiceTradeLiquidityProvider,
  VersionedObject,
  VoiceTradeDrawdownRequest,
  VoiceTradeActionResponse,
  FileUploadRequest,
  CreateExpiryVoiceTradeRequest,
  VoiceTradeNotification,
  nullifyEmptyStringProperties,
  SettingsData,
  Settings,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService, uploadFileWithUpdates } from 'ah-requests';
import { mapValues } from 'lodash';

export class VoiceDeskService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'voiceDeskService' } },
      },
    });
  }

  public listTransactions(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<CashTransaction>>(`${this.baseUrl}cashtransactions`, {
      axiosConfig: { params },
    });
  }

  public updateTransaction(id: string, transaction: Partial<CashTransaction>, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(
      `${this.baseUrl}cashtransactions/${id}`,
      nullifyEmptyStringProperties(transaction),
      {
        options,
      }
    );
  }

  public confirmTransaction(id: string, options?: HttpOptions<VersionedObject>) {
    return this.post<VersionedObject>(`${this.baseUrl}cashtransactions/${id}/confirmation`, {
      options,
    });
  }

  public createTransaction(transaction: Partial<CashTransaction>, options?: HttpOptions<VersionedObject>) {
    return this.post<VersionedObject>(`${this.baseUrl}cashtransactions`, nullifyEmptyStringProperties(transaction), {
      options,
    });
  }

  public bulkCreateTransactions(
    cashTransactions: Partial<CashTransaction>[],
    options?: HttpOptions<{ ignoredDuplicates: string[] }>
  ) {
    return this.post<{ ignoredDuplicates: string[] }>(
      `${this.baseUrl}cashtransactions/bulk`,
      { cashTransactions: cashTransactions.map(this.cleanupPayload) },
      { options }
    );
  }

  public getTransaction(id: string, options?: HttpOptions<CashTransaction>) {
    return this.get<CashTransaction>(`${this.baseUrl}cashtransactions/${id}`, { options });
  }

  public deleteTransaction(id: string, options?: HttpOptions<void>) {
    return this.delete<void>(`${this.baseUrl}cashtransactions/${id}`, { options });
  }

  public downloadTransactions(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Transactions',
    fileName = 'vtd-cash-transactions'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}cashtransactions/export`, query, fileFormat, documentTitle, fileName);
  }

  public deleteVoiceTrade(tradeId: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}voicetrades/${tradeId}`, { options });
  }

  public updateVoiceTrade(trade: Partial<VoiceTradeData>, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${trade.id}`, nullifyEmptyStringProperties(trade), {
      options,
    });
  }

  public bulkCreateVoiceTrade(
    voiceTrades: Partial<VoiceTradeData>[],
    options?: HttpOptions<{ ignoredDuplicates: string[] }>
  ) {
    return this.post<{ ignoredDuplicates: string[] }>(
      `${this.baseUrl}voicetrades/bulk`,
      { voiceTrades: voiceTrades.map(nullifyEmptyStringProperties) },
      { options }
    );
  }

  public createVoiceTrade(trade: Partial<VoiceTradeData>, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.post<VersionedObject>(`${this.baseUrl}voicetrades`, nullifyEmptyStringProperties(trade), { options });
  }

  public getVoiceTrade(tradeId: string, options?: Partial<HttpOptions<VoiceTradeData>>) {
    return this.get<VoiceTradeData>(`${this.baseUrl}voicetrades/${tradeId}`, { options });
  }

  public listVoiceTrades(params: PaginatedQuery) {
    params = standardiseQuerySort({ ...params });

    return this.get<PaginatedResponse<VoiceTradeData>>(`${this.baseUrl}voicetrades`, {
      axiosConfig: { params },
    });
  }

  public downloadVoiceTrades(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Voice Trades',
    fileName = 'vtd-voice-trades'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}voicetrades/export`, query, fileFormat, documentTitle, fileName);
  }

  public rollForwardVoiceTrade(
    originalTradeId: string,
    trade: Partial<VoiceTradeData>,
    rollForwardDate: string,
    options?: Partial<HttpOptions<VoiceTradeActionResponse>>
  ) {
    return this.put<VoiceTradeActionResponse>(
      `${this.baseUrl}voicetrades/${originalTradeId}/rollforward`,
      { ...trade, rollForwardDate },
      {
        options: {
          ...options,
          errors: {
            silent: true,
          },
        },
      }
    );
  }

  public drawdownVoiceTrade(
    originalTradeId: string,
    drawdown: VoiceTradeDrawdownRequest,
    options?: Partial<HttpOptions<VoiceTradeActionResponse>>
  ) {
    const model = nullifyEmptyStringProperties(drawdown);
    return this.put<VoiceTradeActionResponse>(`${this.baseUrl}voicetrades/${originalTradeId}/drawdown`, model, {
      options,
    });
  }

  public amendVoiceTrade(
    tradeId: string,
    amendement: Partial<VoiceTradeData>,
    options?: Partial<HttpOptions<VoiceTradeActionResponse>>
  ) {
    const model = nullifyEmptyStringProperties(amendement);
    return this.put<VoiceTradeActionResponse>(`${this.baseUrl}voicetrades/${tradeId}/amend`, model, { options });
  }

  public unsettleVoiceTrade(tradeId: string, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${tradeId}/unsettle`, undefined, { options });
  }

  public settleVoiceTrade(tradeId: string, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${tradeId}/settle`, undefined, { options });
  }

  public settleInitialMargin(tradeId: string, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${tradeId}/settle-initial-margin`, undefined, {
      options,
    });
  }

  public unsettleInitialMargin(tradeId: string, options?: Partial<HttpOptions<VersionedObject>>) {
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${tradeId}/unsettle-initial-margin`, undefined, {
      options,
    });
  }

  public cancelVoiceTrade(
    tradeId: string,
    trade: Partial<VoiceTradeData>,
    options?: Partial<HttpOptions<VoiceTradeActionResponse>>
  ) {
    const model = nullifyEmptyStringProperties(trade);
    return this.put<VoiceTradeActionResponse>(`${this.baseUrl}voicetrades/${tradeId}/cancel`, model, { options });
  }

  public confirmOptionVoiceTrade(
    tradeId: string,
    trade: Partial<VoiceTradeData>,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const model = nullifyEmptyStringProperties(trade);
    return this.put<VersionedObject>(`${this.baseUrl}voicetrades/${tradeId}/confirm-vanilla-option`, model, {
      options,
    });
  }

  public confirmExpiry(expiryConfirmation: CreateExpiryVoiceTradeRequest) {
    return this.post<VoiceTradeNotification>(`${this.baseUrl}voicetrades/expiry-confirmation`, expiryConfirmation);
  }

  public createLiquidityProvider(data: Partial<VoiceTradeLiquidityProvider>) {
    return this.post<VersionedObject>(`${this.baseUrl}liquidityproviders`, nullifyEmptyStringProperties(data));
  }

  public updateLiquidityProvider(id: string, liquidityProvider: Partial<VoiceTradeLiquidityProvider>) {
    return this.put<VersionedObject>(
      `${this.baseUrl}liquidityproviders/${id}`,
      nullifyEmptyStringProperties(liquidityProvider)
    );
  }

  public deleteLiquidityProvider(id: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}liquidityproviders/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public getLiquidityProvider(id: string, options?: Partial<HttpOptions<VoiceTradeLiquidityProvider>>) {
    return this.get<VoiceTradeLiquidityProvider>(`${this.baseUrl}liquidityproviders/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public listLiquidityProviders(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<VoiceTradeLiquidityProvider>>(`${this.baseUrl}liquidityproviders`, {
      axiosConfig: { params },
    });
  }

  public downloadLiquidityProviders(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Liquidity Providers'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}liquidityproviders/export`, query, fileFormat, documentTitle);
  }

  /**
   * Cleanup method to ensure no stray empty strings are sent to the API in requests
   */
  private cleanupPayload<T extends object>(obj: T) {
    return mapValues(obj, (val) => (val === '' ? null : val)) as T;
  }

  public createBankAccount(data: Partial<BankAccountData>) {
    return this.post<VersionedObject>(`${this.baseUrl}bankaccounts`, nullifyEmptyStringProperties(data));
  }

  public updateBankAccount(id: string, bankAccount: Partial<BankAccountData>) {
    return this.put<VersionedObject>(`${this.baseUrl}bankaccounts/${id}`, nullifyEmptyStringProperties(bankAccount));
  }

  public getBankAccount(id: string, options?: Partial<HttpOptions<BankAccountData>>) {
    return this.get<BankAccountData>(`${this.baseUrl}bankaccounts/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public deleteBankAccount(id: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}bankaccounts/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public listBankAccounts(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<BankAccountData>>(`${this.baseUrl}bankaccounts`, {
      axiosConfig: { params },
    });
  }

  public downloadBankAccounts(query: ListQuery, fileFormat?: ExportListType, documentTitle = 'Bank Accounts') {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}bankaccounts/export`, query, fileFormat, documentTitle);
  }

  public getSettings(key: Settings, options?: Partial<HttpOptions<SettingsData>>) {
    return this.get<SettingsData>(`${this.baseUrl}settings/${key}`, {
      options: {
        ...options,
      },
    });
  }

  public updateSettings(key: Settings, settingsData: Partial<SettingsData>) {
    return this.put<VersionedObject>(`${this.baseUrl}settings/${key}`, settingsData);
  }

  private downloadList(
    url: string,
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle?: string,
    fileName?: string
  ) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
          fileName,
        },
      },
    });
  }

  public uploadSucdenDocument(file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<VersionedObject>(
      {
        axiosConfig: {
          url: `${this.baseUrl}sucden/upload`,
          method: 'POST',
          data: formData,
        },
        options: {
          errors: { silent: true },
        },
      },
      (c) => this.request(c)
    );
  }
}
