import { CollateralVariationMarginConfig, CollateralVariationMarginConfigRequest, VersionedObject } from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';

export class ClientCollateralService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'clientCollateralService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public getCollateralProfileVariationMargin(clientId: string, options?: HttpOptions<CollateralVariationMarginConfig>) {
    return this.get<CollateralVariationMarginConfig>(
      `${this.baseUrl}collateral-profile/${clientId}/variation-margin-configuration`,
      {
        options,
      }
    );
  }

  public updateCollateralProfileVariationMargin(
    variationMarginConfig: CollateralVariationMarginConfigRequest,
    options?: HttpOptions<VersionedObject>
  ) {
    return this.put<VersionedObject>(
      `${this.baseUrl}collateral-profile/${variationMarginConfig.clientId}/variation-margin-configuration`,
      variationMarginConfig,
      { options }
    );
  }
}
